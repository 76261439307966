<template>
  <v-form ref="formElem">
    <v-card>
      <translation-locales />

      <v-card-text>
        <!-- 'name' => Rule::translatable(),
            'key' => 'required|min:3|max:255|unique:modules',
            'active' => 'boolean'
 -->

        <v-text-field
          v-model="form.key"
          label="Key"
          outlined
          :rules="[required]"
          class="mt-5"
        />

        <translatable-input
          v-model="form.name"
          label="Name"
        />
        <translatable-input
          v-model="form.description"
          label="Description"
        />

        <v-select
          v-model="form.module_id"
          placeholder="Select Module"
          :items="modules"
          item-text="title"
          item-value="value"
          single-line
          dense
          outlined
          clearable
          hide-details
          :rules="[required]"
        >
        </v-select>
        <div
          v-if="!modules"
          class="v-messages__message v-messages theme--light error--text"
        >
          Please add a module first.
        </div>

        <v-switch
          v-model="form.active"
          color="primary"
          :label="form.active ? 'Active' : 'Inactive'"
        ></v-switch>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { fetchModules } from '@/api/module/distModule'
import { SearchUser, TranslatableInput, TranslationLocales } from '@/components'
import { required } from '@core/utils/validation'
import { ref } from '@vue/composition-api'

import { useTranslatable } from '@/composables'

export default {
  components: {
    SearchUser,
    TranslatableInput,
    TranslationLocales,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    // Get value from resource prop, use initValue if not exist
    const field = (name, initValue) => ({
      [name]: props.resource ? props.resource[name] : initValue,
    })

    const rules = props.resource ? props.resource.rules : false

    const initialForm = {
      ...field('key', ''),
      ...field('name', useTranslatable(null)),
      ...field('description', useTranslatable(null)),
      ...field('active', true),
      ...field('module_id', null),
    }

    const form = ref({
      ...initialForm,
    })
    const formElem = ref(null)

    const typeOptions = ref([
      {
        name: 'Generic',
        value: 'generic',
      },
      {
        name: 'Company',
        value: 'company',
      },
    ])

    const validate = () => {
      if (formElem.value.validate()) emit('submit', form.value)
    }

    const modules = ref([])

    const loadModules = async () => {
      try {
        const {
          status,
          data: {
            data: { records },
          },
        } = await fetchModules()

        if (status === 200) {
          modules.value = records.map(record => ({
            title: record.name.en,
            value: record.id,
          }))
        }
      } catch (error) {
        console.log(error)
      }
    }

    loadModules()

    return {
      form,
      formElem,
      validate,
      required,

      typeOptions,
      modules,
    }
  },
}
</script>
