import axios from '@axios'

const fetchFeatures = params => axios.get('/admin/features', { params })
const fetchFeature = id => axios.get(`/admin/features/${id}`)
const createFeature = data => axios.post(`/admin/features`, data)
const updateFeature = (id, data) => axios.put(`/admin/features/${id}`, data)
const destroyFeature = id => axios.delete(`/admin/features/${id}`)

// const fetchLayoutPreviewPng = id => axios.get(`/admin/card-layouts/${id}/preview.png`, { responseType: 'blob' })

export { fetchFeatures, fetchFeature, createFeature, updateFeature, destroyFeature }
