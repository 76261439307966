var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "formElem"
  }, [_c('v-card', [_c('translation-locales'), _c('v-card-text', [_c('v-text-field', {
    staticClass: "mt-5",
    attrs: {
      "label": "Key",
      "outlined": "",
      "rules": [_vm.required]
    },
    model: {
      value: _vm.form.key,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "key", $$v);
      },
      expression: "form.key"
    }
  }), _c('translatable-input', {
    attrs: {
      "label": "Name"
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }), _c('translatable-input', {
    attrs: {
      "label": "Description"
    },
    model: {
      value: _vm.form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  }), _c('v-select', {
    attrs: {
      "placeholder": "Select Module",
      "items": _vm.modules,
      "item-text": "title",
      "item-value": "value",
      "single-line": "",
      "dense": "",
      "outlined": "",
      "clearable": "",
      "hide-details": "",
      "rules": [_vm.required]
    },
    model: {
      value: _vm.form.module_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "module_id", $$v);
      },
      expression: "form.module_id"
    }
  }), !_vm.modules ? _c('div', {
    staticClass: "v-messages__message v-messages theme--light error--text"
  }, [_vm._v(" Please add a module first. ")]) : _vm._e(), _c('v-switch', {
    attrs: {
      "color": "primary",
      "label": _vm.form.active ? 'Active' : 'Inactive'
    },
    model: {
      value: _vm.form.active,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "active", $$v);
      },
      expression: "form.active"
    }
  })], 1), _c('v-card-actions', {
    staticClass: "justify-center"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.validate
    }
  }, [_vm._v(" Submit ")])], 1), _vm._l(_vm.errors, function (msg) {
    return _c('v-alert', {
      key: msg,
      attrs: {
        "color": "warning"
      }
    }, [_vm._v(" " + _vm._s(msg) + " ")]);
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }