var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "feature-list"
    }
  }, [_c('v-card', {
    staticClass: "filter-section"
  }, [_c('v-row', {}, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card-title', {
    staticClass: "font-weight-bold "
  }, [_vm._v(" " + _vm._s(_vm.$t('common.list.filters')) + " ")])], 1), _c('v-col', {
    staticClass: "d-flex justify-end",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        _vm.showFilter = !_vm.showFilter;
      }
    }
  }, [_c('v-icon', [_vm._v(_vm._s(_vm.showFilter ? _vm.icons.mdiMenuUp : _vm.icons.mdiMenuDown))])], 1)], 1)], 1), _c('v-expand-transition', [_c('v-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showFilter,
      expression: "showFilter"
    }],
    staticClass: "px-2 ma-0"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "single-line": "",
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "clearable": "",
      "placeholder": _vm.$t('common.list.searchByName')
    },
    model: {
      value: _vm.filters.name,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "name", $$v);
      },
      expression: "filters.name"
    }
  })], 1)], 1)], 1)], 1), _c('v-row', {
    staticClass: "my-0 d-flex justify-end"
  }, [_c('v-col', {
    staticStyle: {
      "margin-right": "auto"
    },
    attrs: {
      "cols": "12",
      "sm": "2",
      "align": "right"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "block": "",
      "loading": _vm.loading,
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.loadFeatures
    }
  }, [_c('v-icon', {
    staticClass: "prefix-icon"
  }, [_vm._v(" " + _vm._s(_vm.icons.mdiRefresh) + " ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('common.list.refresh')) + " ")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "2",
      "align": "right"
    }
  }, [_c('v-btn', {
    attrs: {
      "depressed": "",
      "block": "",
      "color": "success"
    },
    on: {
      "click": function click($event) {
        return _vm.setAside(null);
      }
    }
  }, [_c('v-icon', {
    staticClass: "prefix-icon"
  }, [_vm._v(" " + _vm._s(_vm.icons.mdiPlus) + " ")]), _vm._v(" " + _vm._s(_vm.$t('common.list.create')) + " ")], 1)], 1)], 1), _c('v-data-table', {
    staticClass: "text-no-wrap",
    attrs: {
      "headers": _vm.tableColumns,
      "items": _vm.tableItems,
      "options": _vm.options,
      "server-items-length": _vm.tableTotal,
      "loading": _vm.loading,
      "header-props": _vm.headerprops
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.options = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item.id",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" #" + _vm._s(item.id) + " ")];
      }
    }, {
      key: "item.description",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('span', {
          staticClass: "text-no-wrap"
        }, [_vm._v(_vm._s(_vm.t(item.description)))])];
      }
    }, {
      key: "item.active",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('span', {
          staticClass: "text-no-wrap"
        }, [_vm._v(_vm._s(item.active ? 'Yes' : 'No'))])];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "d-flex align-center justify-center"
        }, [_c('v-menu', {
          attrs: {
            "bottom": "",
            "left": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref5) {
              var on = _ref5.on,
                attrs = _ref5.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "small": "",
                  "icon": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "size": "18"
                }
              }, [_vm._v(" " + _vm._s(_vm.icons.mdiDotsVertical) + " ")])], 1)];
            }
          }], null, true)
        }, [_c('v-list', [_c('v-list-item', {
          attrs: {
            "link": ""
          },
          on: {
            "click": function click($event) {
              return _vm.setAside(item);
            }
          }
        }, [_c('v-list-item-title', [_c('v-icon', {
          staticClass: "me-2",
          attrs: {
            "size": "18"
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiPencilOutline) + " ")]), _c('span', [_vm._v("Edit")])], 1)], 1), _c('v-list-item', {
          attrs: {
            "link": ""
          },
          on: {
            "click": function click($event) {
              return _vm.deleteFeature(item);
            }
          }
        }, [_c('v-list-item-title', [_c('v-icon', {
          staticClass: "me-2",
          attrs: {
            "size": "18"
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiDeleteOutline) + " ")]), _c('span', [_vm._v("Delete")])], 1)], 1)], 1)], 1)], 1)];
      }
    }], null, true)
  }), _vm.aside ? _c('feature-aside', {
    attrs: {
      "resource": _vm.resource
    },
    on: {
      "changed": function changed($event) {
        _vm.loadFeatures();
        _vm.aside = false;
      }
    },
    model: {
      value: _vm.aside,
      callback: function callback($$v) {
        _vm.aside = $$v;
      },
      expression: "aside"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }